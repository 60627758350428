import * as Sentry from '@sentry/nextjs';
import type {NextPageContext} from 'next';
import NextErrorComponent, {ErrorProps} from 'next/error';

// const SentryErrorComponent = async (props: ErrorProps) => {
//   Sentry.captureException(props.)
//   return <NextErrorComponent {...props} />
// }

const CustomErrorComponent = (props: ErrorProps) => (
  <NextErrorComponent {...props} />
);

CustomErrorComponent.getInitialProps = async (contextData: NextPageContext) => {
  console.log('logging error: ', contextData.err);
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return NextErrorComponent.getInitialProps(contextData);
};

export default CustomErrorComponent;
